import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Layout from "../../../components/chat-channel/Layout";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import { useMedia } from "use-media";
import "react-accessible-accordion/dist/fancy-example.css";

import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";

const topImage = require("../../../assets/images/market_ui/slack/blog_image.png");

const header_image = require("../../../assets/img/blogs/product_blog_header.png");
const section_1 = require("../../../assets/img/blogs/product_blog_image_1.png");
const section_2 = require("../../../assets/img/blogs/product_blog_image_2.png");
const section_3 = require("../../../assets/img/blogs/product_blog_image_3.png");
const section_4 = require("../../../assets/img/blogs/product_blog_image_4.png");
const section_5 = require("../../../assets/img/blogs/product_blog_image_5.png");
const section_6 = require("../../../assets/img/blogs/product_blog_image_6.png");
const section_7 = require("../../../assets/img/blogs/product_blog_image_7.png");
const section_8 = require("../../../assets/img/blogs/product_blog_image_8.png");
const section_9 = require("../../../assets/img/blogs/product_blog_image_9.png");
const section_10 = require("../../../assets/img/blogs/product_blog_image_10.png");
const section_11 = require("../../../assets/img/blogs/product_blog_image_11.png");
const section_12 = require("../../../assets/img/blogs/product_blog_image_12.png");

const arrow_open = require("../../../assets/images/testimonials/arrow_open_blog.svg");
const arrow_close = require("../../../assets/images/testimonials/arrow_close_blog.svg");
const author = require("../../../assets/img/blogs/deepa_2.png");
const author_mobile = require("../../../assets/img/blogs/mobile_deepa_2.png");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isMobile = useMedia({ maxWidth: "520px" });
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <>
      <TitleAndMetaTags
        title="Product release notes | May 2023"
        description="Get ready for an enhanced experience with the Assistant platform's latest features! Discover exciting updates such as improved bot adoption and an enhanced bot dashboard."
        keywords={[
          "service desk chatbot",
          "AI-powered service desk assistant",
          "Chatbot for IT service desk",
        ]}
        ogImage={header_image}
        schema={ldSchema}
      />

      <Container>
        <Layout backgroundColor={"roi_bg"} logoFor="ASSISTANT">
          <section className="w-100 float-left blog_top_header_upt product_blog_header_upt">
            <div className="container">
              <div
                className={`flex_cont_top_blog_img text-align-center ${
                  isMobile ? "" : "float-left"
                }`}
              >
                {isMobile ? null : null}
              </div>
            </div>
          </section>
          {isMobile ? <ContentContainerMobile /> : <ContentContainer />}
          {isMobile ? <RelatedBlogs /> : null}
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}
function ContentContainer() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  return (
    <section className="w-100 float-left margin-bottom-40 blog_content_parent_container">
      <div className="container">
        <div className="grid_container_goto_second grid_container_blogs_content">
          <div className="grid-left-container-goto blogs_grid_left_container w-100">
            <div className="sticky_container_goto_case">
              <h2 className="font-section-sub-header-small text-decoration-none">
                Table of Contents
              </h2>
              <hr className="underline_blogs_toc mt-0 mb-4"></hr>
              <div className="redirection-flex-container-blog">
                <AnchorLink
                  offset={180}
                  href="#section1"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section1"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section1")}
                >
                  1. Chatbot
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section2"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section2"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section2")}
                >
                  2. Workflow
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section3"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section3"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section3")}
                >
                  3. Manage Users
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section4"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section4"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section4")}
                >
                  4. Chat Channels
                </AnchorLink>
              </div>
            </div>
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                We've got some big news to share! The wait is finally over, and
                we're thrilled to announce the release of our most awaited
                features on the Assistant platform. We've been working hard to
                bring new features to your experience!
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                So, what's new?
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                We've added some fantastic features, including bot adoption and
                an enhanced bot dashboard that will give you more insight into
                your bot's performance and usage. We've also got plenty more up
                our sleeve, so be sure to keep an eye out for what's coming
                next!
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                We understand that our users are the heart and soul of our
                platform, and that's why we're committed to delivering the best
                possible experience. With these new features, we hope to take
                your experience with the Assistant platform to the next level.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Let’s dive into what we have for you.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs mt-3">
                Chatbot
              </h2>
              <h3 className="font-section-sub-header-small-home ">
                Pro-active chatbot notifications:
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                Until now, the initial interaction is always triggered by the
                end user/employees to resolve their workplace issues. Now
                onwards, the chatbot can send notifications to users in chat
                when there is an event (i.e., wish employees their birthday/work
                anniversary, notify them on ticket updates, and so on).
              </p>

              <img src={section_1} className="blog_image_top_bt" />

              <img src={section_2} className="blog_image_top_bt" />
              <h3 className="font-section-sub-header-small-home ">
                Advanced chatbot dashboard:
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                Using our advanced chatbot dashboard analytics, you can measure
                chatbot usage, performance, and ROI. It gives you information
                related to the number of sessions, number of user queries
                resolved/escalated to agents/abandoned by the users, new vs
                return users, chatbot ROI, etc.
              </p>
              <img src={section_3} className="blog_image_top_bt" />

              <img src={section_4} className="blog_image_top_bt" />
              <h3 className="font-section-sub-header-small-home ">
                Get accurate bot resolution status:
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                You can now use the additional settings to make the query
                resolution status based on the conversation flow. Use the “Jump
                To” and “Exit” functions in the Dialog builder to update the
                dialog resolution status.
              </p>

              <img src={section_6} className="blog_image_top_bt" />
              <img src={section_7} className="blog_image_top_bt" />
              <h3 className="font-section-sub-header-small-home ">
                Ability to prompt the user before the session is expired:
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                You will now be able to send a prompt to the user in chat before
                the session expiry to let the user know the session is about to
                expire from the Chatbot settings page.
              </p>
              <img src={section_8} className="blog_image_top_bt" />

              <h3 className="font-section-sub-header-small-home ">
                Ability to view chatbot logs with the session breakdown:
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                Now, you can view the chatbot logs based on the number of
                queries for each session asked by the user that are resolved by
                the bot/escalated to the agent/abandoned by the users.
              </p>
              <img src={section_9} className="blog_image_top_bt" />
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Workflow
              </h2>
              <h3 className="font-section-sub-header-small-home ">
                Application Triggers:
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                Until now, the end-user can only execute the app workflow using
                the chatbot to resolve their workplace issues. With the new
                feature, app workflow can be executed when an event or activity
                happens in the application. For example, a ticket is created in
                ServiceNow.
              </p>
              <img src={section_5} className="blog_image_top_bt" />

              <h3 className="font-section-sub-header-small-home ">
                Ability to pause and execute the app workflows based on time:
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                The timer feature in the Workflow builder will help you pause
                the app workflow and resume based on the time interval you
                choose.
              </p>
              <img src={section_10} className="blog_image_top_bt" />
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Manage Users
              </h2>
              <h3 className="font-section-sub-header-small-home ">
                New design to support the role-based access control:
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                Manage users page is now updated with new design to support
                Role-based access control on who can make changes to the
                chatbot, and the app workflows.
              </p>
              <img src={section_11} className="blog_image_top_bt" />
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Chat Channels
              </h2>
              <h3 className="font-section-sub-header-small-home ">
                New design for Omni Channel page:
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                Omni channel page design is updated to improve the user
                experience to deploy the chatbot in Slack, Teams, and as a
                Widget on the website.
              </p>
              <img src={section_12} className="blog_image_top_bt" />
              <p className="font-section-normal-text-testimonials line-height-18">
                Thanks for your time. Watch out this space for more updates.
              </p>
            </div>

            <AuthorBio />
            <RelatedBlogs />
          </div>
        </div>
      </div>
    </section>
  );
}
function ContentContainerMobile() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  const [tableoFContentsDisplay, settableoFContentsDisplay] = React.useState(
    "hide"
  );
  const onArrowClick = () => {
    if (tableoFContentsDisplay === "show") {
      settableoFContentsDisplay("hide");
    } else {
      settableoFContentsDisplay("show");
    }
  };

  return (
    <section className="w-100 float-left mb-3 mt-4">
      <div className="container">
        <div className="grid_container_goto_second grid_blogs_container_mobile">
          <div className="grid-left-container-goto">
            <div
              className="mobile_accordian_title_goto_case"
              onClick={onArrowClick}
            >
              <h2 className="font-section-sub-header-small mb-3">
                Table of Contents
              </h2>
              {tableoFContentsDisplay === "show" ? (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_close}
                ></img>
              ) : (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_open}
                ></img>
              )}
            </div>
            {tableoFContentsDisplay === "show" ? (
              <div className="mobile_accordian_content_goto_case mt-4">
                <div className="redirection-flex-container">
                  <AnchorLink
                    offset={180}
                    href="#section1"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section1"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section1")}
                  >
                    1. Chatbot
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section2"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section2"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section2")}
                  >
                    2. Workflow
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section3"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section3"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section3")}
                  >
                    3. Manage Users
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section4"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section4"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section4")}
                  >
                    4. Chat Channels
                  </AnchorLink>
                </div>
              </div>
            ) : null}
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                We've got some big news to share! The wait is finally over, and
                we're thrilled to announce the release of our most awaited
                features on the Assistant platform. We've been working hard to
                bring new features to your experience!
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                So, what's new?
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                We've added some fantastic features, including bot adoption and
                an enhanced bot dashboard that will give you more insight into
                your bot's performance and usage. We've also got plenty more up
                our sleeve, so be sure to keep an eye out for what's coming
                next!
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                We understand that our users are the heart and soul of our
                platform, and that's why we're committed to delivering the best
                possible experience. With these new features, we hope to take
                your experience with the Assistant platform to the next level.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Let’s dive into what we have for you.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs mt-3">
                Chatbot
              </h2>
              <h3 className="font-section-sub-header-small-home ">
                Pro-active chatbot notifications:
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                Until now, the initial interaction is always triggered by the
                end user/employees to resolve their workplace issues. Now
                onwards, the chatbot can send notifications to users in chat
                when there is an event (i.e., wish employees their birthday/work
                anniversary, notify them on ticket updates, and so on).
              </p>

              <img src={section_1} className="blog_image_top_bt" />

              <img src={section_2} className="blog_image_top_bt" />
              <h3 className="font-section-sub-header-small-home ">
                Advanced chatbot dashboard:
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                Using our advanced chatbot dashboard analytics, you can measure
                chatbot usage, performance, and ROI. It gives you information
                related to the number of sessions, number of user queries
                resolved/escalated to agents/abandoned by the users, new vs
                return users, chatbot ROI, etc.
              </p>
              <img src={section_3} className="blog_image_top_bt" />

              <img src={section_4} className="blog_image_top_bt" />
              <h3 className="font-section-sub-header-small-home ">
                Get accurate bot resolution status:
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                You can now use the additional settings to make the query
                resolution status based on the conversation flow. Use the “Jump
                To” and “Exit” functions in the Dialog builder to update the
                dialog resolution status.
              </p>

              <img src={section_6} className="blog_image_top_bt" />
              <img src={section_7} className="blog_image_top_bt" />
              <h3 className="font-section-sub-header-small-home ">
                Ability to prompt the user before the session is expired:
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                You will now be able to send a prompt to the user in chat before
                the session expiry to let the user know the session is about to
                expire from the Chatbot settings page.
              </p>
              <img src={section_8} className="blog_image_top_bt" />

              <h3 className="font-section-sub-header-small-home ">
                Ability to view chatbot logs with the session breakdown:
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                Now, you can view the chatbot logs based on the number of
                queries for each session asked by the user that are resolved by
                the bot/escalated to the agent/abandoned by the users.
              </p>
              <img src={section_9} className="blog_image_top_bt" />
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Workflow
              </h2>
              <h3 className="font-section-sub-header-small-home ">
                Application Triggers:
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                Until now, the end-user can only execute the app workflow using
                the chatbot to resolve their workplace issues. With the new
                feature, app workflow can be executed when an event or activity
                happens in the application. For example, a ticket is created in
                ServiceNow.
              </p>
              <img src={section_5} className="blog_image_top_bt" />

              <h3 className="font-section-sub-header-small-home ">
                Ability to pause and execute the app workflows based on time:
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                The timer feature in the Workflow builder will help you pause
                the app workflow and resume based on the time interval you
                choose.
              </p>
              <img src={section_10} className="blog_image_top_bt" />
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Manage Users
              </h2>
              <h3 className="font-section-sub-header-small-home ">
                New design to support the role-based access control:
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                Manage users page is now updated with new design to support
                Role-based access control on who can make changes to the
                chatbot, and the app workflows.
              </p>
              <img src={section_11} className="blog_image_top_bt" />
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Chat Channels
              </h2>
              <h3 className="font-section-sub-header-small-home ">
                New design for Omni Channel page:
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                Omni channel page design is updated to improve the user
                experience to deploy the chatbot in Slack, Teams, and as a
                Widget on the website.
              </p>
              <img src={section_12} className="blog_image_top_bt" />
              <p className="font-section-normal-text-testimonials line-height-18">
                Thanks for your time. Watch out this space for more updates.
              </p>
            </div>
          </div>
        </div>
      </div>
      <AuthorBio />
    </section>
  );
}
export const AuthorBio = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <section className="w-100 float-left author_bio_parent_cont">
      <div className="author_bio_flex_container">
        {isMobile ? (
          <div className="author_bio_flex_left align-self-start">
            <img src={author_mobile} className="width-40"></img>

            <div className="align-self-center">
              <h2 className="font-section-sub-header-small-home mt-3">
                Deepa Majumder
              </h2>
              <h4 className="font-section-normal-text-testimonials">
                Content Writer
              </h4>{" "}
            </div>
          </div>
        ) : (
          <div className="author_bio_flex_left align-self-start">
            <img src={author}></img>
            <h2 className="font-section-sub-header-small-home mt-3">
              Deepa Majumder
            </h2>
            <h4 className="font-section-normal-text-testimonials">
              Content Writer
            </h4>{" "}
          </div>
        )}

        <div className="author_bio_flex_right w-100">
          <p className="font-section-normal-text-testimonials line-height-18">
            Deepa Majumder is a writer who nails the art of crafting bespoke
            thought leadership articles to help business leaders tap into rich
            insights in their journey of organization-wide digital
            transformation. Over the years, she has dedicatedly engaged herself
            in the process of continuous learning and development across
            business continuity management and organizational resilience.{" "}
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Her pieces intricately highlight the best ways to transform employee
            and customer experience. When not writing, she spends time on
            leisure activities.
          </p>
        </div>
      </div>
    </section>
  );
};
const RelatedBlogs = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <div
      className={`most_popular_links ${isMobile ? "mt-4 container" : "mt-5"}`}
    >
      <div className="most_popular_header font-section-sub-header">
        Related Articles
      </div>
      <div className="most_popular_ul">
        <ul
          className="section__ul font-section-normal-text ul_related_blogs_upd"
          style={{ width: "100%", float: "left", borderBottom: "none" }}
        >
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/service-desk-chatbot-guide"
              className="font-section-normal-text-testimonials"
            >
              Service Desk Chatbot Automation: A Complete Guide
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/itsm-chatbot-guide"
              className="font-section-normal-text-testimonials"
            >
              ITSM CHATBOT: Use Cases and Benefits Explained
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/service-desk-automation"
              className="font-section-normal-text-testimonials"
            >
              {" "}
              Service Desk Automation: ELEVATE YOUR IT SUPPORT EFFICIENCY
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
